<template>
  <div class="developRM home">
    <div class="develop_top">
      <h2>
        {{ $t("technology_title") }}
        <br />{{ $t("technology_title_1") }}
      </h2>
      <img src="@/assets/images/reviewmind/reviewmindDemo.gif" alt />
      <div class="develop_img">
        <ul>
          <li>
            <h3>{{ $t("technology_AI") }}</h3>
            <p>{{ $t("technology_AI_1") }}</p>
          </li>
          <li>
            <h3>{{ $t("technology_NLP") }}</h3>
            <p>{{ $t("technology_NLP_1") }}</p>
          </li>
          <li>
            <h3>{{ $t("technology_visualization") }}</h3>
            <p>{{ $t("technology_visualization_1") }}</p>
          </li>
          <li>
            <img src="@/assets/images/reviewmind/logo3.png" alt="로고" />
          </li>
        </ul>
        <img src="@/assets/images/reviewmind/develop_1.png" alt="이미지" />
      </div>
    </div>

    <h2>
      <img src="@/assets/images/reviewmind/circle.png" alt="이미지" />
      {{ $t("technology_context_title") }}
    </h2>
    <ul>
      <li>
        <img src="@/assets/images/reviewmind/develop_2.png" alt="로고" />
        <h2>
          {{ $t("technology_analysis") }}
          <span>{{ $t("technology_analysis_1") }}</span>
          <p>
            {{ $t("technology_analysis_text") }}
          </p>
        </h2>
        <!-- <button>Watch Demo</button> -->
        <div></div>
      </li>
      <li>
        <img src="@/assets/images/reviewmind/develop_6.png" alt="로고" />
        <h2>
          {{ $t("technology_topic") }}
          <span>{{ $t("technology_topic_1") }}</span>
          <p>
            {{ $t("technology_topic_text") }}
          </p>
        </h2>
        <!-- <button>Watch Demo</button> -->
        <div class="li_2"></div>
      </li>
      <li>
        <img src="@/assets/images/reviewmind/develop_4.png" alt="로고" />
        <h2>
          {{ $t("technology_text") }}
          <span>{{ $t("technology_text_1") }}</span>
          <p>
            {{ $t("technology_text_text") }}
          </p>
        </h2>
        <!-- <button>Watch Demo</button> -->
        <div class="li_3"></div>
      </li>
      <li>
        <img src="@/assets/images/reviewmind/develop_3.png" alt="로고" />
        <h2>
          {{ $t("technology_recognition") }}
          <span>{{ $t("technology_recognition_1") }}</span>
          <p>
            {{ $t("technology_recognition_text") }}
          </p>
        </h2>
        <!-- <button>Watch Demo</button> -->
        <div class="li_4"></div>
      </li>
      <li>
        <img src="@/assets/images/reviewmind/develop_5.png" alt="로고" />
        <h2>
          {{ $t("technology_machine") }}
          <span>{{ $t("technology_machine_1") }}</span>
          <p>{{ $t("technology_machine_text") }}</p>
        </h2>
        <!-- <button>Watch Demo</button> -->
        <div class="li_5"></div>
      </li>
      <li></li>
    </ul>
    <div class="mainSection4">
      <div class="section4Wrap">
        <h2>{{ $t("demo_request") }}</h2>
        <a href="tel:02-713-5847">
          <button>{{ $t("support") }}</button>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    this.$store.dispatch("GNB_MENU", 3);
  },
};
</script>